import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5024afaa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "panel-index has-background-contents" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloserController = _resolveComponent("CloserController")!
  const _component_ItemTreeIndex = _resolveComponent("ItemTreeIndex")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CloserController, {
      modelValue: _ctx.show,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.show) = $event)),
      text: _ctx.$t('form.index'),
      class: "title"
    }, null, 8, ["modelValue", "text"]),
    _withDirectives(_createVNode(_component_ItemTreeIndex, {
      "top-pid-number": _ctx.contentTreeIndex.root,
      item: _ctx.contentTreeIndex,
      children: _ctx.contentTreeIndex.children,
      show: _ctx.show,
      tag: "div"
    }, null, 8, ["top-pid-number", "item", "children", "show"]), [
      [_vShow, _ctx.show]
    ])
  ]))
}