
import { defineComponent, ref, watchEffect } from 'vue'
import CloserController from '@/components/molecules/CloserController.vue'
import ItemTreeIndex from '../ItemTreeIndex.vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

export default defineComponent({
  components: {
    CloserController,
    ItemTreeIndex,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup () {
    const store = useStore()
    const route = useRoute()

    const pid = route.params.pid
    store.dispatch('indexSearch', pid)
    const contentTreeIndex = ref()

    watchEffect(() => {
      contentTreeIndex.value = store.getters.indexResponse
    })
    return {
      show: ref(true),
      contentTreeIndex,
    }
  },
})
